<template>
  <div class="topic-single-container">
    <div v-if="topic" class="topic-block">
      <div class="block trunk content">
        <div
            v-for="(item, ii) in trunks"
            :key="ii"
            :class="{
            level1: item.level === 1,
            level2: item.level === 2,
            level3: item.level === 3,
            level4: item.level === 4,
          }"
            class="item"
        >
          <span v-if="item.level > 1" style="display: inline-block">{{ item.shorter }}</span>
          <div class="body" v-html="item.trunk" />
        </div>
        <div v-if="topic.items && topic.items.length" class="choice">
          <div v-for="(option, oi) in topic.items" :key="oi" class="option" style="display:flex;">
            <span>{{ option.value }}、</span>
            <span v-html="option.text" />
          </div>
        </div>
      </div>
      <div v-if="studentAnswer" class="block student-answer">
        <div class="tag section1">
          学生答案情况
        </div>
        <div class="content">
          <div v-for="(item, index) in studentAnswer" :key="item.topicId" class="student-answer-item">
            <span>{{index + 1}}、</span>
            <div style="display:flex;align-items:center;">
              <span class="answer-btn right-btn" v-if="item.answerChecked === 1">✔</span>
              <span class="answer-btn error-btn" v-if="item.answerChecked === 0">×</span>
            </div>

          </div>
        </div>
      </div>
      <div class="block">
        <div class="tag section5">
          正确答案
        </div>
        <div class="content">
          <div v-for="(itemAnswer, iai) in answers" :key="iai" class="item">
            <span v-if="itemAnswer.level > 1" style="display: inline-block">{{ itemAnswer.shorter }}</span>
            <div class="body" v-html="itemAnswer.answer" />
          </div>
        </div>
      </div>
      <div v-if="topic.topicAnalyze" class="block">
        <div class="tag section2">
          题目解析
        </div>
        <div class="content" v-html="topic.topicAnalyze" />
      </div>
      <div v-if="topic.topicAnalysis" class="block">
        <div class="tag section6">
          解题思路
        </div>
        <div class="content" v-html="topic.topicAnalysis" />
      </div>
      <div v-if="topic.media.length" class="block">
        <div class="tag section3">
          解题视频
        </div>
        <div class="content">
          <div v-for="(v, vi) in topic.media" :key="vi" class="video" @click="play(v)">
            <svg t="1657531706675" class="icon" viewBox="0 0 1024 1024" p-id="8948">
              <path d="M512 149.333333c200.298667 0 362.666667 162.368 362.666667 362.666667s-162.368 362.666667-362.666667 362.666667S149.333333 712.298667 149.333333 512 311.701333 149.333333 512 149.333333z m-50.56 234.666667c-19.2 0-34.773333 15.573333-34.773333 34.773333v182.997334a34.773333 34.773333 0 0 0 53.546666 29.248l143.808-92.394667a34.773333 34.773333 0 0 0-0.256-58.666667l-143.808-90.602666a34.773333 34.773333 0 0 0-18.538666-5.354667z" p-id="8949" fill="#ffffff" />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div v-if="error">{{error}}</div>
    <transition name="fade">
      <div v-if="loading" class="loading">
        正在加载...
      </div>
    </transition>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import request from "../../request";
export default {
  name: "Topic",
  data() {
    return {
      content: "",
      id: null,
      token: null,
      topic: null,
      trunks: null,
      answers: null,
      studentAnswer: null,
      loading: true,
      error: ''
    }
  },
  created() {
    this.setParams()
    this.loadMathJax()

    document.title = '题目详情'
  },
  watch: {
    $route() {
      this.setParams()
      this.getData()
    }
  },
  methods: {
    setParams() {
      const params = this.$route.query
      this.id = params.id
      this.studentAnswer = params.studentAnswer ? JSON.parse(params.studentAnswer) : null
      this.token = params.token
      console.log('this.studentAnswer', this.studentAnswer)
    },
    loadMathJax() {
      window.MathJax = {
        startup: {
          pageReady: () => {
            return window.MathJax.startup.defaultPageReady().then(() => {
              if (this.id) {
                this.getData()
              }
            })
          }
        },
        tex: {
          inlineMath: [['$', '$'], ['\\(', '\\)'], ['\\[', '\\]']],
          displayMath: [['$$', '$$']]
        }
      }
      this.loadScript()
    },

    loadScript() {
      const script = document.createElement('script')
      const head = document.getElementsByTagName('head')[0]
      script.type = 'text/javascript'
      script.async = true
      script.src = 'https://pen.zwres.com/static-assets/mathJax/es5/tex-svg.js'
      head.appendChild(script)
    },

    getData() {
      //  var xmlhttp = new XMLHttpRequest();
      //  xmlhttp.open("GET", `https://staging-api.msy5.com/api/repo-topic/v2/${this.id}`, true);
      //  xmlhttp.setRequestHeader('Authorization', 'Bearer ' + this.token)
      //  xmlhttp.send();
      //  xmlhttp.onreadystatechange =  () => { 
      //     console.log('readyState', xmlhttp.readyState)
      //     console.log('xmlhttp.status', xmlhttp.status)
      //     if (xmlhttp.readyState === 4) { 
      //       if (xmlhttp.status>=200 && xmlhttp.status<300 ||xmlhttp.status===304 ){
      //         console.log('xmlhttp.responseText', xmlhttp.responseText)
      //         this.content = xmlhttp.responseText
      //         const response = JSON.parse(xmlhttp.responseText)

      //         // console.log('response', response)
      //         this.loading = false
      //         this.topic = this.treeToList(response.data)
      //         this.error = ''
      //         this.math()

      //       }else{
      //           console.log("没有接收到服务器的数据");
      //           this.loading = false
      //       }
      //     }

      //  }


      
      request({
        url: '/api/repo-topic/v2/' + this.id,
        method: 'get',
        headers: {
          'Authorization': 'Bearer ' + this.token
        }
      }).then(response => {
        this.loading = false
        this.topic = this.treeToList(response.data)
        this.error = ''
        this.math()
      }).catch(error => {
        console.log(error)
        this.loading = false
        this.error = `题目数据有误-${this.id}`
        // alert(error.message)
      })
    },

    math() {
      this.$nextTick(() => {
        if (window.MathJax) {
          window.MathJax.typeset()
        }
      })
    },
    treeToList(topic) {
      const trunks = []
      const answers = []
      const t = (node, parent) => {
        node.level = parent.level + 1
        if (node.structureId === 1) {
          answers.push({
            number: parseInt(node.sort),
            shorter: node.trunkNumber,
            level: node.level,
            answer: node.answerHtml
          })
        } else {
          node.answer.forEach(answer => {
            answers.push({
              number: parseInt(node.sort),
              shorter: node.trunkNumber,
              level: node.level,
              answer: answer
            })
          })
        }
        trunks.push({
          level: node.level,
          number: parseInt(node.sort),
          shorter: node.trunkNumber,
          trunk: node.topicTrunk
        })
        if (node.children && node.children.length) {
          node.children.forEach(child => {
            t(child, node)
          })
        }
      }
      t(topic, { level: 0 })
      this.trunks = trunks
      this.answers = answers
      return topic
    },
    play(video) {
      // console.log('url===>', url)
      const u = navigator.userAgent
      const android = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1
      const isIOS = /iPhone|iPad|iPod/i.test(u.toLowerCase())
      if (android) {
        if (window.zw_android) {
          window.zw_android.androidMethod(0, JSON.stringify({url: video.mediaPath, id: video.id}))
        }
      }

      // 判断当前环境是否为小程序
      const ua = navigator.userAgent.toLowerCase()
      // console.log('ua', ua)
      // console.log('boolean', /MicroMessenger/i.test(ua))
      if (/MicroMessenger/i.test(ua)) {
        wx.miniProgram.getEnv((res) => {
          console.log('res', res)
          if (res.miniprogram) {
            console.log('在小程序内')
            wx.miniProgram.navigateTo({
              url: `/pages/playVideo/playVideo?id=${this.topic.topicId}&url=${encodeURIComponent(video.mediaPath)}`
            })
          }
        })
      } else if(isIOS) {
        try {
          // window.webkit.messageHandlers.getMessage.postMessage(jsonStr)
          window.webkit.messageHandlers.getMessage.postMessage(JSON.stringify({url: video.mediaPath, id: video.id}));
        } catch(error) {
          console.log('error', error)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.topic-single-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 20px;
  font-size: 14px;
  .topic-block {
    padding: 20px 10px 0 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    .block {
      position: relative;
      border-radius: 10px;
      box-shadow: 0 0 7px #ebebec;
      padding: 40px 10px 20px 10px;
      margin-bottom: 20px;
      overflow-x: auto;
      .tag {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #90C909;
        padding: 4px 20px;
        color: #fff;
        border-bottom-right-radius: 10px;
        border-top-left-radius: 10px;
      }
      .content {
        color: #666;

        .student-answer-item {
          display: flex;
          align-items: center;

          &:not(:last-child) {
            margin-bottom: 15px;
          }
        }

        .answer-btn {
          width: 34px;
          height: 34px;
          font-size: 20px;
          border-radius: 50%;
          border: 1px solid;
          border-color: #A6A6A6;
          color: #A6A6A6;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .answer-btn +.answer-btn  {
          margin-left: 20px;
        }

        .right-btn {
          background-color: #4690FF;
          border-color: #4690FF !important;
          color: #fff !important;
        }

        .error-btn {
          background-color: #FF7575;
          border-color: #FF7575 !important;
          color: #fff !important;
        }

        .video {
          background-color: #000;
          color: #fff;
          position: relative;
          width: 100%;
          height: 200px;
          .icon {
            width: 50px;
            height: 50px;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -25px;
            margin-left: -25px;
            opacity: .7;
          }
        }
      }
      .section1 {
        background-color:#8dbd4d;
      }
      .section2 {
        background-color:#fd7a72;
      }
      .section3 {
        background-color:#5686c6;
      }
      .section4 {
        background-color:#f5b436;
      }
      .section5 {
        background-color:#c46fff;
      }
      .section6 {
        background-color:#34b7af;
      }
      .section7 {
        background-color:#f07043;
      }
    }
    .trunk {
      padding-top: 10px;
      color: #666;

      img {
        object-fit: contain;
        border: 1px solid #b040ff;
      }

      ::v-deep p {
        margin: 0;
        padding: 0;
      }
    }
    .content {
      .item {
        display: flex;
        span {
          margin-right: 4px;
        }
        img {
          object-fit: contain;
          border: 1px solid #b040ff;
        }
      }
    }
    .level2 {
      padding-left: 10px;
    }
    .level3 {
      padding-left: 20px;
    }
    .level4 {
      padding-left: 30px;
    }
  }
  .loading {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
}
</style>
